<template>
  <sb-page-content title="Dashboard">
    <v-row v-if="!isMobile">
      <v-col cols="12">
        <sb-card title="Overview"
                 disable-padding>
          <iframe id="embedded-mzng-dashboard-overview"
                  :src="dashboardUrls.overview"
                  title="Dashboard - Overview"
                  width="1200px"
                  height="850px"
                  style="border:none;" />
        </sb-card>
      </v-col>
      <v-col cols="12">
        <sb-card title="Vehicle Details"
                 disable-padding>
          <iframe id="embedded-mzng-dashboard-vehicle"
                  :src="dashboardUrls.vehicle"
                  title="Dashboard - Vehicle"
                  width="1200px"
                  height="2000px"
                  style="border:none;" />
        </sb-card>
      </v-col>
    </v-row>

    <sb-page-message v-else
                     v-model="mobileDashboardMessage" />
  </sb-page-content>
</template>

<script>
import SbPageMessage from '@/components/core/SbPageMessage';

export default {
  components: {
    SbPageMessage,
  },

  data() {
    return {
      mobileDashboardMessage: {
        icon: 'mdi-monitor-dashboard',
        title: 'Dashboard Disabled',
        message: 'Dashboard is currently not supported on Mobile. Please switch to desktop to view the Daashboard',
        iconColor: 'secondary',
      },
      dashboardUrls: {
        overview: process.env.VUE_APP_DASH_OVERVIEW_URL,
        vehicle: process.env.VUE_APP_DASH_VEHICLE_URL,
      },
    };
  },
};
</script>
